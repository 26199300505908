import React, {Component} from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';

import AddObject from './AddObject/AddObject';
import EditObject from './EditObject/EditObject';

import * as actions from '../../store/actions/index';
import Table from '../../components/UI/Table/Table';
import Filter from '../../components/UI/Table/Filter/Filter';
import Export from '../../components/UI/Table/Export/Export';
import Fieldable from '../../components/UI/Table/Fieldable/Fieldable';
import FieldableItem from '../../components/UI/Table/Fieldable/Item/FieldableItem';
import Head from '../../components/UI/Table/Row/Head';
import Row from '../../components/UI/Table/Row/Row';
import Action from '../../components/UI/Table/Action/Action';
import FilterItem from '../../components/UI/Table/Filter/Item/FilterItem';
import NoData from '../../components/UI/Menu/NoData/NoData';
import Parse from '../../Parse';
import Configs from '../../Configs';
import Func from '../../helpers/Func';

class Objects extends Component {

    constructor(props) {
        super(props);

        let defaultExportSettings = {
            fieldDelimiter: ","
        }
        const savedExportSettings = localStorage.getItem(props.match.params.classes + "_Export_Settings");
        if(savedExportSettings !== undefined && savedExportSettings) {
            defaultExportSettings = {
                ...JSON.parse(savedExportSettings)
            }
        }

        this.state = {
            queries: [],
            classes: props.match.params.classes,
            order: {},
            checked: [],
            fieldables: [],
            isCheckedAll: false,
            openExport: false,
            openFilter: false,
            openFieldable: false,
            page: 1,
            limit: 100,
            reload: false,
            configs: {},
            downloadUrl: null,
            exportLoading: false,
            notPermission: false,
            exportSettings: {
                ...defaultExportSettings
            }
        };
    }

    setNewReduxClassesState = () => {
        const stateParams = {...this.state};
        this.props.onCurrentStateObjects(stateParams);
        this.props.onCurrentClassesObjects(this.state.classes);
    }

    onFetchObjects = (classes, queries, order, page, limit) => {
        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
		if (values.s_id !== undefined) {
			token = values.s_id;
        }
        if (values.c_id !== undefined) {
			controller = values.c_id;
        }
        if(!token || !controller) {
            this.setState({
                notPermission: true
            })
        }
        this.props.onFetchObjects(classes, queries, order, page, limit, token, controller);
    }

    loadClasses = () => {
        if ( this.props.match.params.classes ) {
            const queries = queryString.parse(this.props.location.search)
            let defaultQueries = [];
            if(queries !== undefined && queries) {
                const queryKeys = Object.keys(queries);
                const queryKeysLength = queryKeys.length;
                for(let i = 0; i < queryKeysLength; i++) {
                    const queryKey = queryKeys[i];
                    if(queryKey === 'c_id' || queryKey == 's_id' || queryKey == 'a_id') {
                        continue;
                    }
                    if(queries[queryKey] !== undefined && Func.isDateFormat(queries[queryKey])) {
                        defaultQueries.push({
                            param: queryKey,
                            value: [
                                moment(queries[queryKey]).subtract(1, "days").format("YYYY-MM-DD"), 
                                moment(queries[queryKey]).add(1, "days").format("YYYY-MM-DD"), 
                            ],
                            where: "range_date"
                        })
                    }
                    else {
                        defaultQueries.push({
                            param: queryKey,
                            value: queries[queryKey],
                            where: "equals",
                        })
                    }
                }
            }
            let defaultState = {
                queries: [],
                classes: this.props.match.params.classes,
                order: {},
                checked: [],
                fieldables: [],
                isCheckedAll: false,
                openFilter: false,
                openFieldable: false,
                openExport: false,
                page: 1,
                limit: 100,
                reload: false
            };
            if ( !this.state.classes || (this.state.classes && this.state.classes !== this.props.match.params.classes) ) {
                if(this.props.currentState && this.props.currentClasses === this.props.match.params.classes) {
                    defaultState = {...this.props.currentState};
                }
                else {
                    defaultState = {
                        ...defaultState,
                        queries: [...defaultQueries]
                    }
                }
                this.setState(defaultState, () => {
                    this.props.onFetchNewObjects();
                    this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
                })
            }
            else {
                const currentQueries = this.props.currentState !== undefined && this.props.currentState.queries !== undefined ? this.props.currentState.queries : [];
                const stateQueries = this.state.queries !== undefined ? this.state.queries : [];
                console.log("currentQueries", currentQueries);
                console.log("defaultQueries", defaultQueries);
                console.log("stateQueries", stateQueries);
                if(
                    defaultQueries !== undefined && 
                    queries !== undefined && queries && 
                    defaultQueries.length > 0 && 
                    stateQueries.length > 0 &&
                    JSON.stringify(defaultQueries) !== JSON.stringify(stateQueries)
                ) {
                    this.setState({
                        ...defaultState,
                        queries: [...defaultQueries]
                    }, () => {
                        if(JSON.stringify(stateQueries) === JSON.stringify(currentQueries)) {
                            this.props.onCurrentStateObjects({...this.state});
                            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
                        }
                    });
                }
            }
        }
    }

    selectedHandler = (index) => {
        const checked = [...this.state.checked];
        if(checked.indexOf(index) >= 0) {
            checked.splice(checked.indexOf(index), 1);
        }
        else {
            checked.push(index);
        }
        this.setState({
            checked: checked
        })
    }

    allSelectedHandler = () => {
        let checked = [];
        this.props.objects.forEach( object => {
            if(this.state.isCheckedAll) {
                checked.splice(checked.indexOf(object.id), 1);
            }
            else {
                checked.push(object.id);
            }
        });
        this.setState({
            checked: checked,
            isCheckedAll: !this.state.isCheckedAll
        })
    }

    componentDidMount () {
        if(this.props.currentState) {
            this.setState({
                ...this.props.currentState
            }, () => {
                this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
                // this.loadClasses(); 
            });
        }
        else {
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
            // this.loadClasses(); 
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.configs !== this.props.configs) {
            this.loadConfigs();
        }
        this.loadClasses();   
    }

    loadConfigs = () => {
        const configs = {...this.props.configs};
        const fieldables = [...this.state.fieldables];
        const configFieldables = {...configs._fields};

        const savedFieldables = localStorage.getItem(this.state.classes + "_Fieldables");
        let defaultFieldables = [];
        if(savedFieldables !== undefined && savedFieldables) {
            defaultFieldables = [
                ...JSON.parse(savedFieldables)
            ]
        }
        if(defaultFieldables.length > 0) {
            this.setState({
                fieldables: [
                    ...defaultFieldables.filter((el) => {
                        if(
                            configFieldables[el.type] !== undefined && 
                            configFieldables[el.type].list !== undefined && 
                            configFieldables[el.type].list
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    })
                ]
            });
            return;
        }
        const fields = Object.keys(configFieldables).filter((el) => {
            if(configFieldables[el].list) {
                return true;
            }
            else {
                return false;
            }
        }).map((el) => {
            return {
                default: configFieldables[el].default || null,
                formatValue: configFieldables[el].typeValue,
                format: configFieldables[el].type,
                type:  el,
                label: configFieldables[el].name,
                value: true,
                order: this.getOldOrders(el, fieldables)
            }
        });
        this.setState({
            fieldables: [
                ...fields,
                ...[{
                    type: 'createdAt',
                    label: 'Created At',
                    value: true,
                    order: this.getOldOrders('createdAt', fieldables)
                }],
                ...[{
                    type: 'updatedAt',
                    label: 'Updated At',
                    value: true,
                    order: this.getOldOrders('updatedAt', fieldables)
                }],
            ]
        })
    }

    getOldOrders = (el, fieldables) => {
        const fieldablesLength = fieldables.length;
        for(let i = 0; i < fieldablesLength; i++) {
            if(fieldables[i].order && el === fieldables[i].type) {
                return fieldables[i].order;
            }
        }
        return false;
    }
    
    searchHandler = () => {
        this.setState({
            page: 1
        }, () => {
            this.openFilterHandler();
            this.props.onFetchNewObjects();
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    searchInputHandler = (key, param) => {
        this.setState({
            queries: {
                ...this.state.queries,
                [key]: param
            }
        })
    }

    editedHandler = (id) => {
        this.setNewReduxClassesState();
        let query = "";
        if(this.props.location && this.props.location.search) {
            query = `${this.props.location.search}`;
        }
        this.props.history.push( '/object/'+ this.state.classes +'/edit/' + id + query );
    }

    clickRowHandler = (value, index, param) => {
        const fields = {...param.fields};
        const field = fields[index];
        const configs = {...this.props.configs};
        const isLinkTo = typeof configs._fields[field] !== 'undefined' && typeof configs._fields[field].linkTo !== 'undefined' && configs._fields[field].linkTo ? true : false;

        this.setNewReduxClassesState();

        let query = "";
        if(this.props.location && this.props.location.search) {
            query = `${this.props.location.search}`;
        }

        if(isLinkTo) {
            const relations = [...param.relations];
            const relationsLength = relations.length;
            for(let i = 0; i < relationsLength; i++) {
                if(relations[i]._relation_param !== field) {
                    continue;
                }
                this.props.history.push( '/object/'+ relations[i]._classes +'/edit/' + relations[i]._value + query );
            }
        }
        else {
            this.props.history.push( '/object/'+ this.state.classes +'/edit/' + param.key + query );
        }
    }

    addedHandler = () => {
        let query = "";
        if(this.props.location && this.props.location.search) {
            query = `${this.props.location.search}`;
        }
        this.props.history.push( '/object/'+ this.state.classes +'/add' + query );
    }

    deleteMultiHandler = () => {

    }

    deletedHandler = (id) => {
        this.deleteMember(id);
    }

    deleteMember = (id) => {
        const configs = {...this.props.configs};
        const ObjectModel = Parse.Object.extend(this.state.classes);
        const query = new Parse.Query(ObjectModel);
        query.get(id).then((object) => {
            Swal.fire({
                title: 'Are you sure to delete user?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    object.destroy().then((res) => {
                        Swal.fire('Delete '+configs._name+' successful!');
                        this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
                    }, function (error) {
                        alert('Delete '+configs._name+' fail');
                    });
                }
            })
        }, (error) => {
            Swal.fire(configs._name+' is invalid!');
        });
    }

    nextPageHandler = () => {
        const currentPage = this.state.page;
        const nextPage = currentPage + 1;
        this.setState({
            page: nextPage
        }, () => {
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, nextPage, this.state.limit);
        })
    }

    prevPageHandler = () => {
        const currentPage = this.state.page;
        if(currentPage === 1) {
            return;
        }
        const prevPage = currentPage - 1;
        this.setState({
            page: prevPage
        }, () => {
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, prevPage, this.state.limit);
        })
    }

    openFilterHandler = () => {
        this.setState({
            openFilter: !this.state.openFilter,
            openFieldable: false,
            openExport: false,
        },() => {
            const queries = [...this.state.queries];
            if(queries.length <= 0) {
                this.filterAddedHandler();
            }
        })
    }

    openFieldableHandler = () => {
        this.setState({
            openFieldable: !this.state.openFieldable,
            openFilter: false,
            openExport: false
        })
    }

    openExportHandler = () => {
        this.setState({
            openFieldable: false,
            openFilter: false,
            openExport: !this.state.openExport,
        })
    }

    refreshedHandler = () => {
        this.setState({
            queries: [],
            checked: [],
            fieldables: [],
            isCheckedAll: false,
            openFilter: false,
            openFieldable: false,
            openExport: false,
            page: 1
        }, () => {
            localStorage.removeItem(this.state.classes + "_Fieldables")
            this.props.onFetchNewObjects();
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    softRefreshedHandler = () => {
        let defaultState = {
            queries: [],
            checked: [],
            fieldables: [],
            isCheckedAll: false,
            openFilter: false,
            openFieldable: false,
            openExport: false,
            page: 1
        };
        if(this.props.currentState) {
            defaultState = {...this.props.currentState};
        }
        this.setState(defaultState, () => {
            this.props.onFetchNewObjects();
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    filterAddedHandler = () => {
        const queries = [...this.state.queries];
        const fieldables = {...this.state.fieldables};
        queries.push({
            param: fieldables[0].type || null,
            where: 'starts_with',
            value: null
        });
        this.setState({
            queries: queries
        })
    }

    filterRemovedHandler = (index) => {
        const queries = [...this.state.queries];
        queries.splice(index, 1);
        this.setState({
            queries: queries
        })
    }

    selectFilterParam = (event, index) => {
        const queries = [...this.state.queries];
        const item = {
            ...queries[index],
            'param': event.target.value
        }
        queries[index] = item;
        this.setState({
            queries: queries
        })
    }

    selectFilterWhere = (event, index) => {
        const queries = [...this.state.queries];
        const item = {
            ...queries[index],
            'where': event.target.value
        }
        queries[index] = item;
        this.setState({
            queries: queries
        })
    }

    selectFilterValue = (event, index) => {
        const queries = [...this.state.queries];
        const item = {
            ...queries[index],
            'value': event.target.value
        }
        queries[index] = item;
        this.setState({
            queries: queries
        })
    }

    selectDateFilterValue = (event, picker, index) => {
        const queries = [...this.state.queries];
        const item = {
            ...queries[index],
            'where': 'range_date',
            'value': [
                picker.startDate.format('YYYY-MM-DD'),
                picker.endDate.format('YYYY-MM-DD')
            ]
        }
        queries[index] = item;
        this.setState({
            queries: queries
        })
    }

    selectedFieldable = (index) => {
        const fieldables = [...this.state.fieldables];
        const item = {
            ...fieldables[index],
            'value': !fieldables[index].value 
        }
        fieldables[index] = item;
        this.setState({
            fieldables: fieldables
        }, () => {
            localStorage.setItem(this.state.classes + "_Fieldables", JSON.stringify(fieldables))
        })
    }

    scrollHandler = (e) => {
        if(this.props.finish || this.props.loadingMore) {
            return;
        }
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            const currentPage = this.state.page;
            const nextPage = currentPage + 1;
            this.setState({
                page: nextPage
            }, () => {
                this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
            })
        }
    }

    selectLimitHandler = (e) => {
        const limit = +e.target.value;
        this.setState({
            queries: [],
            order: {},
            checked: [],
            fieldables: [],
            isCheckedAll: false,
            openFilter: false,
            openFieldable: false,
            openExport: false,
            page: 1,
            limit: limit,
            reload: false
        }, () => {
            this.props.onFetchNewObjects();
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    changeOrderHandler = (value) => {
        const fieldables = [...this.state.fieldables];
        const configs = {...this.props.configs};
        const configFieldables = {...configs._fields};
        let orderState = {};
        const dataFieldables = fieldables.map((element) => {
            if(element.type !== 'updatedAt' && element.type !== 'createdAt' && !configFieldables[element.type].sort) {
                return {
                    typeValue: element.typeValue,
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: this.getOldOrders(element.type, fieldables)
                };
            }
            if(element === value) {
                const order = value.order === 'desc' ? 'asc' : 'desc'; 
                orderState = {
                    param: element.type,
                    value: order
                }
                return {
                    typeValue: element.typeValue,
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: order
                }
            }
            else {
                return {
                    typeValue: element.typeValue,
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: this.getOldOrders(element.type, fieldables)
                }
            }
        })
        this.setState({
            fieldables: dataFieldables,
            order: orderState,
            page: 1
        }, () => {
            this.onFetchObjects(this.state.classes, this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    onExportHandler = (requestPage) => {
        this.setState({
            exportLoading: true,
            downloadUrl: null
        });
        let page = this.state.page;
        if(Number.isInteger(+requestPage)) {
            page = +requestPage;
        }
        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
        if (values.s_id !== undefined) {
            token = values.s_id;
        }
        if (values.c_id !== undefined) {
            controller = values.c_id;
        }
        Parse.Cloud.run("paymentsExport", {
            classes: this.state.classes,
            page: page,
            timezone: moment().utcOffset(),
            limit: this.state.limit,
            order: this.state.order,
            queries: this.state.queries,
            controller: controller,
            settings: {...this.state.exportSettings}
        }, {
            sessionToken: token
        })
        .then((res) => {
            this.setState({
                exportLoading: false,
                downloadUrl: res.path || null
            });
        })
        .catch(e => {
            Swal.fire("Error when export file");
            this.setState({
                exportLoading: false
            });
        })
    }

    onDownloadHandler = () => {
        window.open(this.state.downloadUrl,'_blank');
    }

    render () {
        if(this.state.notPermission) {
            return (
                <div className="row" style={{
                    paddingTop: "20px"
                }}>
                    <div className="col-md-12">
                        <div className="box">
                            <div className="box-body">
                                <NoData></NoData>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const configs = {...this.props.configs};
        const configFieldables = configs._fields;

        let tableUsers = null;
        let head = null;
        let rows = null;
        let limits = [
            this.state.limit,
        ];
        if(typeof configs._limit !== 'undefined' && configs._limit.length > 0) {
            limits = [...configs._limit];
        }

        head = <Head items={this.state.fieldables} 
            changeOrders={(value) => this.changeOrderHandler(value)} 
            isCheckbox={true} allSelected={() => this.allSelectedHandler()} 
            isCheckedAll={this.state.isCheckedAll}
            hasActions={true}
            configs={configFieldables}
        ></Head>

        let objects = null;
        
        objects = this.props.objects.map( member => {
            let items = [];
            let fields = [];
            let relations = [];
            let linkToItems = [];
            this.state.fieldables.forEach((value, key) => {
                if(value.value) {
                    if(
                        (member[value.type]) && 
                        (value.format === "date" || value.format === "datetime") && 
                        (value.formatValue == "number" || value.formatValue == "text")
                    ) {
                        const date = new Date(+(member[value.type]));
                        items.push(date);
                    }
                    else {
                        if(
                            (
                                member[value.type] == undefined || 
                                member[value.type] == null || 
                                member[value.type] == ""
                            ) &&
                            value.default
                         ) {
                            items.push(value.default);    
                        }
                        else {
                            items.push(member[value.type]);
                        }
                    }
                    fields.push(value.type);
                    if(typeof member['relation_' + value.type] !== 'undefined') {
                        relations.push(member['relation_' + value.type]);
                        linkToItems.push(key);
                    }
                }
            })

            return {
                fields: fields,
                relations: relations,
                items: items,
                linkToItems: linkToItems,
                key: member.id
            }
        });

        let filter = null;
        if(this.state.openFilter === true) {
            const params = Object.keys(configFieldables).filter((el) => {
                if(configFieldables[el].filter) {
                    return true;
                }
                return false;
            });

            const mapNames = params.map(value => {
                return configFieldables[value].name;
            })

            const paramsQueries = [
                ...params,
                'createdAt',
                'updatedAt'
            ]

            const paramsLabels = [
                ...mapNames,
                'Created At',
                'Updated At'
            ]

            let queries = [...this.state.queries];
            queries = queries.filter((val) => {
                if(val.param == "c_id" || val.param == "s_id" || val.param == "a_id") {
                    return false;
                }
                return true;
            }).map((value, index) => {
                return (
                    <FilterItem filterParams={paramsQueries} 
                        filterLabels={paramsLabels}
                        key={index} 
                        item={value} 
                        removed={() => this.filterRemovedHandler(index)}
                        isDate={value.param === 'createdAt' 
                            || value.param === 'updatedAt' 
                            || configFieldables[value.param].type === 'datetime'
                            || configFieldables[value.param].type === 'date' 
                            ? true 
                            : false
                        }
                        selectedParam={(e) => this.selectFilterParam(e, index)}
                        selectedWhere={(e) => this.selectFilterWhere(e, index)}
                        selectedValue={(e) => this.selectFilterValue(e, index)}
                        handleDateTimeApply={(e, picker) => this.selectDateFilterValue(e, picker, index)}
                    ></FilterItem>
                )
            })

            filter = (
                <Filter 
                    queries={this.state.queries}
                    clearAll={this.refreshedHandler}
                    added={this.filterAddedHandler}
                    applied={this.searchHandler}
                >
                    {queries}
                </Filter>
            );
        }

        let fieldable = null;
        if(this.state.openFieldable === true) {
            fieldable = [...this.state.fieldables];
            fieldable = fieldable.map((value, index) => {
                return (
                    <FieldableItem
                        key={index} 
                        item={value.label} 
                        selected={value.value}
                        onSelected={() => this.selectedFieldable(index)}
                    ></FieldableItem>
                )
            })
            fieldable = (
                <Fieldable 
                    clearAll={this.refreshedHandler}
                    applied={this.applyFieldableHandler}
                >
                    {fieldable}
                </Fieldable>
            );
        }

        let exportUI = null;
        if(this.state.openExport === true) {
            exportUI = (
                <Export
                    applied={this.onExportHandler}
                    download={this.onDownloadHandler}
                    loading={this.state.exportLoading}
                    onChangeSetting={(value) => {
                        this.setState({
                            exportSettings: {...value}
                        }, () => {
                            localStorage.setItem(this.state.classes + "_Export_Settings", JSON.stringify(value))
                        })
                    }}
                    settings={this.state.exportSettings}
                    url={this.state.downloadUrl}
                    configs={configFieldables}
                    limit={this.state.limit}
                    page={this.state.page}
                ></Export>
            );
        }
        
        rows = objects.map((value, index) => {
            const selected = this.state.checked.indexOf(value.key) >= 0 ? true : false;
            return (
                <Row key={index} 
                    edited={() => this.editedHandler(value.key)} 
                    linkItems={value.linkToItems}
                    deleted={() => this.deletedHandler(value.key)} 
                    items={value.items} 
                    onSelected={() => this.selectedHandler(value.key)}
                    checked={selected}
                    dataId={value.key}
                    clickRow={(el,k) => this.clickRowHandler(el, k, value)}
                    configs={configs}
                    fieldables={configFieldables}
                ></Row>
            );
        })

        const actions = (
            <Action
                onAdded={() => this.addedHandler()}
                onRefreshed={this.refreshedHandler}
                isOpenFilter={this.state.openFilter}
                isOpenFieldable={this.state.openFieldable}
                isOpenExport={this.state.openExport}
                filterToggled={() => this.openFilterHandler()}
                fieldToggled={() => this.openFieldableHandler()}
                onExport={this.openExportHandler}
                filter={filter}
                fieldable={fieldable}
                exportUI={exportUI}
                configs={configs}
            >
            </Action>
        );

        tableUsers = (
            <Table head={head} 
                title={configs._name}
                description={configs._desc}
                rows={rows}
                actions={actions}
                deleted={() => this.deleteMultiHandler()}
                search={() => this.searchHandler()}
                next={() => this.nextPageHandler()}
                prev={() => this.prevPageHandler()}
                onScrollToEnd={(e) => this.scrollHandler(e)}
                loading={this.props.loading}
                loadingMore={this.props.loadingMore}
                total={this.props.total}
                currentTotal={this.props.currentTotal}
                limits={limits}
                limit={this.state.limit}
                selectLimit={(e) => this.selectLimitHandler(e)}
            ></Table>
        )
        
        return (
            <div>
                {tableUsers}
                <Switch>
                    <Route path={'/object/:classes/edit/:id'} render={(props) => <EditObject {...props} onSubmit={this.softRefreshedHandler} />} />
                    <Route path={'/object/:classes/add'} render={(props) => <AddObject classes={this.state.classes} {...props} onSubmit={this.softRefreshedHandler} />} />
                </Switch>             
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        objects: state.object.objects,
        configs: state.object.configs,
        loading: state.object.loading,
        loadingMore: state.object.loadingMore,
        finish: state.object.finish,
        total: state.object.total,
        currentTotal: state.object.currentTotal,
        currentState: state.object.currentState,
        currentClasses: state.object.currentClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchObjects: (classes, queries, order, page, limit, token, controller) => {
            return dispatch( actions.fetchObjects(classes, queries, order, page, limit, token, controller) );
        },
        onFetchNewObjects: () => {
            return dispatch( actions.fetchNewObjects() );
        },
        onCurrentStateObjects: (state) => {
            return dispatch( actions.fetchCurentStateObjects(state) );
        },
        onCurrentClassesObjects: (classes) => {
            return dispatch( actions.fetchCurentClassesObjects(classes) );
        },
        onCurrentTotalObjects: (total) => {
            return dispatch( actions.fetchCurrentObjectTotal(total) );
        }
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Objects );