import React from 'react';

import "./MoreButton.css";
import MoreOrderButton from './Order';
import MorePaymentButton from './Payment';
import MoreOnlinePaymentButton from './OnlinePayment';
import MoreGrabFoodSyncMenuButton from './GrabFoodSyncMenu';
import MoreGrabFoodPauseStoreButton from './GrabFoodPauseStore';
import MoreGrabFoodOrderButton from './GrabFoodOrder';

class MoreButton extends React.PureComponent {
    render () {
        switch(this.props.classes) {
            case "paymentMerchant2":
                return (
                    <React.Fragment>
                        <MoreOrderButton {...this.props} controllerKey={`controllerId`} createOrderKey={`customOrderId`}></MoreOrderButton>
                        <MorePaymentButton {...this.props} partnerOrderKey={`partnerOrderId`} amountKey={`amount`} onSubmit={this.props.onSubmit}></MorePaymentButton>
                    </React.Fragment>
                );
            case "GPayOrders":
                return (
                    <React.Fragment>
                        <MoreOrderButton {...this.props} controllerKey={`restaurant_id`} createOrderKey={`partnerOrderId`}></MoreOrderButton>
                    </React.Fragment>
                );
            case "GrabFoodRestaurant":
                return (
                    <React.Fragment>
                        <MoreGrabFoodSyncMenuButton {...this.props}></MoreGrabFoodSyncMenuButton>
                        <MoreGrabFoodPauseStoreButton {...this.props}></MoreGrabFoodPauseStoreButton>
                    </React.Fragment>
                );
            case "GrabFoodOrders":
                return (
                    <React.Fragment>
                        <MoreOrderButton {...this.props} controllerKey={`restaurant`} createOrderKey={`grabfoodOrderId`}></MoreOrderButton>
                        <MoreGrabFoodOrderButton {...this.props}></MoreGrabFoodOrderButton>
                    </React.Fragment>
                );
            case "smsOrder":
                return (
                    <React.Fragment>
                        {/* <MorePaymentButton {...this.props} controllerKey={`restaurent_id`} createOrderKey={`partnerOrderId`}></MorePaymentButton> */}
                        <MoreOnlinePaymentButton {...this.props} controllerKey={`controller`} createOrderKey={`createOrderId`}></MoreOnlinePaymentButton>
                    </React.Fragment>
                );
            default: 
                return null
        }
    }
}

export default MoreButton;